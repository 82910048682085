html,
body {
  overflow-x: hidden;
}

* {
  font-family: 'IBM Plex Sans', sans-serif;
}

h1 {
  color: #556069;
}

a {
  color: #556069;
}

h3 {
  text-align: center;
}

p {
  font-size: 16px;
  color: #556069;
}

.fade-in {
  transition-property: opacity;
  transition-duration: 1s;
  transition-delay: 1s;
}

.containerDiv {
  width: 90%;
  height: max-content;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 4px 6px 2px rgba(0, 0, 0, 0.1);
  flex-direction: row;
}

.photocarousel {
  width: 100vw;
  height: 100vh;
  background-image: url('https://images.unsplash.com/photo-1557562440-b67d58679232?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80');
  background-size: cover;
}

.homeDescription {
  font-size: 1.6rem;
  margin-bottom: 5rem;
  z-index: 9999;
}

/* landing */

.landing-page {
  width: 100vw;
  background-image: url('https://res.cloudinary.com/dpcx0po55/image/upload/v1563976318/FitzekPancini/Header/IMG_2706_7_czpkec.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 5;
  background-position: center;
}

.landing-gradient {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.99) 20%,
    rgba(255, 255, 255, 0.95) 25%,
    rgba(255, 255, 255, 0.89) 30%,
    rgba(255, 255, 255, 0.83) 38%,
    rgba(255, 255, 255, 0.75) 45%,
    rgba(255, 255, 255, 0.3) 100%
  );
}

.landing-content {
  position: relative;
  z-index: 15;
}

.landingDiv {
  align-items: center;
  margin-bottom: 4rem;
  height: 100vh;
}

.landingText {
  color: rgb(199, 199, 199);
  font-size: 2rem;
  text-align: block;
  text-shadow: 1px 1px grey;
}

.landingLinks {
  padding-top: 75px;
  width: max-content;
  max-width: 90vw;
  max-height: 100vh;
  /* flex-wrap: wrap; */
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  animation-duration: 3s;
  animation-timing-function: ease-in;
}

@keyframes slidein {
  from {
    margin-left: 200%;
    /* width: 300%; */
  }

  to {
    margin-left: 0%;
    /* width: 100%; */
  }
}

.landing-card-wrapper {
  opacity: 0;
  /* width: 20%; */
}

.landing-card {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px;
  width: 300px;
  /* height: 300px; */

  box-shadow: 0 4px 6px 2px rgba(0, 0, 0, 0.1);
  transition: 250ms all ease-in-out;
}

.landing-card:hover {
  box-shadow: 0 4px 10px 2px rgba(0, 0, 0, 0.25);
  transform: scale(1.1);
}

.landingLinks img {
  width: 100%;
  max-height: 80%;
  margin-bottom: 15px;
  /* box-shadow: 10px 10px 5px grey; */
}

.landingLinks h2 {
  color: #556069;
  font-size: 22px;
  word-wrap: break-word;
}

.landingLinks a {
  text-decoration: none;
}

/* About Us */
.profil {
  /* background-image: url('https://images.unsplash.com/photo-1558066858-246fbbf9446f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=564&q=80'); */
  background-size: cover;
  height: 100vh;
}

.profilDiv {
  width: 90%;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 4px 6px 2px rgba(0, 0, 0, 0.1);
}

.profil h1 {
  color: #556069;
  font-size: 3rem;
}

.profileCards {
  width: 40%;
  height: max-content;
  margin: 1rem;
}

.profileCards h3 {
  color: #556069;
}
.profileCards p {
  color: #556069;
}
.profileCards > * {
  margin: 1rem;
}

/* .profileImage {
  width: 35%;
} */

.collaborators {
  width: inherit;
  height: max-content;
  text-align: center;
  color: #556069;
}

/* Kontakt */

.kontaktForm {
  width: 40rem;
  height: max-content;
  padding: 3rem;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 4px 6px 2px rgba(0, 0, 0, 0.1);
  flex-wrap: wrap;
}

.kontaktForm h1 {
  color: #556069;
}

.kontakttext {
  color: #556069;
  width: 90.8%;
  text-align: center;
}

.kontaktForm input {
  width: 14rem;
  height: max-content;
  border: none;
  border-bottom: 1px solid #556069;
}

.kontaktForm textarea {
  width: 100%;
}

#nachricht {
  width: 90.8%;
  height: 10rem;
  border: none;
  border-bottom: 1px solid #556069;
}

#submitbutton {
  border: 1px solid #556069;
  color: #556069;
  margin-top: 10px;
  background-color: white;
}

/* Cards */

.backgrounddimmed {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0%;
  left: 0%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1020;
}

.cardsDiv {
  width: 90%;
  height: max-content;
  background-color: rgba(250, 250, 250, 0.9);
  /* box-shadow: 0 4px 6px 2px rgba(0, 0, 0, 0.1); */
  flex-direction: row;
}

.cards {
  margin: 2rem;
  width: 35rem;
  height: 31.1rem;
  box-shadow: 0 4px 6px 2px rgba(0, 0, 0, 0.1);
}

.dreiDimg {
  height: 25rem;
  opacity: 0.7;
}

.projectdetails {
  position: fixed;
  width: 60vw;
  height: 60vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1040;
}

.bauaufmaß {
  margin-top: 10rem;
  background-image: url('https://res.cloudinary.com/dpcx0po55/image/upload/v1563350343/FitzekPancini/Header/SA_AK_Nordfassade_1_bhrcit.jpg');
  background-size: cover;
  height: 400px;
}

.photogrammetrie {
  background-image: url('https://res.cloudinary.com/dpcx0po55/image/upload/v1563976311/FitzekPancini/Header/Epischrein_gesamt_tsjyit.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-top: 4rem;
  /* background-size: 110%; */
  opacity: 0.8;
  height: 400px;
}

.bestandsanalyse {
  background-image: url('https://res.cloudinary.com/dpcx0po55/image/upload/v1563350337/FitzekPancini/Header/Fa_01_20_1_ixihez.jpg');
  background-size: cover;
  background-position-x: center;
  margin-top: 4rem;
  height: 400px;
}

.bauforschung {
  background-image: url('https://res.cloudinary.com/dpcx0po55/image/upload/v1563976311/FitzekPancini/Header/Vorderansicht_Website_fivwsc.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-top: 4rem;
  opacity: 0.7;
  height: 400px;
}

.dreid {
  background-image: url('https://res.cloudinary.com/dpcx0po55/image/upload/v1564407840/FitzekPancini/Header/Wohnung-1-Dollhouse-View_dt2uvy.jpg');
  background-size: cover;
  background-position: center;
  background-position-y: top;
  margin-top: 4rem;
  opacity: 0.7;
  height: 400px;
}

/* Footer  */

.footer {
  background-color: #ca9e93;
  height: 30vh;
  z-index: 1030;
}

/* Impressum  */

.impressum h5 {
  font-weight: bold;
}

/* Design */

.width100p {
  width: 100%;
}
.width90p {
  width: 90%;
}
.width70p {
  width: 50%;
}

.width100v {
  width: 100vw;
}

.width75p {
  width: 75%;
}

.width50v {
  width: 50vh;
}

.textWidth {
  width: 70%;
}

.h-90 {
  height: 90vh;
}

.height100v {
  height: 100vh;
}

.height50v {
  height: 50vh;
}

.maxContent {
  height: max-content;
}

.grey {
  background-color: grey;
}

.margin0 {
  margin: 0;
}
.margin1 {
  margin: 1rem;
}
.marginr1 {
  margin-right: 1rem;
}
.marginBot1 {
  margin-bottom: 1rem;
}
.marginBot2 {
  margin-bottom: 2rem;
}
.marginBot4 {
  margin-bottom: 4rem;
}
.marginTop2 {
  margin-top: 2rem;
}
.marginTop3 {
  margin-top: 3rem;
}
.marginTop4 {
  margin-top: 3.5rem;
}
.marginTop6 {
  margin-top: 6rem;
}
.marginTop15 {
  margin-top: 15rem;
}
.padding1 {
  padding: 1rem;
}
.paddingleftright1 {
  padding-left: 3rem;
  padding-right: 3rem;
}
.padding5 {
  padding: 5rem;
}
.paddingtop15 {
  padding-top: 1.5rem;
}
.paddingtopbot2 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.paddingtopbot5 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.paddingbot5 {
  padding-bottom: 5rem;
}
.paddingbot2 {
  padding-bottom: 2rem;
}
.paddingbot1 {
  padding-bottom: 1rem;
}
.paddingtop3 {
  padding-top: 3rem;
}

.flexed {
  display: flex;
}

.row {
  flex-direction: row;
}

.column {
  flex-direction: column;
}

.justcenter {
  justify-content: center;
}

.flex-start {
  justify-content: flex-start;
}

.flex-end {
  justify-content: flex-end;
}

.spacearound {
  justify-content: space-around;
}

.spacebetween {
  justify-content: space-between;
}

.evenly {
  justify-content: space-evenly;
}

.aligncenter {
  align-items: center;
}

.alignaround {
  align-content: space-around;
}

.mr-auto > * {
  margin-left: 1rem;
}

.background {
  background-color: blue;
}

.absolute {
  position: absolute;
}

.border {
  border: 1px solid #556069;
}
.borderbot {
  border-bottom: 1px solid #556069;
}

.wrap {
  flex-wrap: wrap;
}

/* images */

.circle {
  border-radius: 40px;
}

.profileImage {
  width: 35%;
}

/* text  */

.textCenter {
  text-align: center;
}

/* colors */

.darkblue {
  color: #556069;
}

.textcolor {
  color: #556069;
}

.textcolor a {
  color: white;
}

.textcolor a:hover {
  color: #556069;
  text-decoration: none;
}

.logocolor a {
  color: #556069;
}

.logocolor a:hover {
  color: black;
  text-decoration: none;
}

.logoText h4 {
  font-size: 16px;
  margin: 0;
}

.nodecoration:hover {
  text-decoration: none;
}

.textwhite {
  color: white;
}

/* links  */

.nostyle a:hover {
  color: red;
}

@media (min-width: 2000px) {
  p {
    font-size: 33px;
  }
  a {
    font-size: 33px;
  }
  h1 {
    font-size: 4.5rem;
  }
  /* h3 {
    font-size: 33px;
  } */

  .logo img {
    width: 4rem;
    height: 5rem;
  }
  .logoText h4 {
    font-size: 28px;
  }
  .bauaufmaß {
    margin-top: 6rem;
    height: 600px;
  }
  .bauforschung {
    margin-top: 6rem;
    height: 600px;
  }
  .bestandsanalyse {
    margin-top: 6rem;
    height: 600px;
  }
  .photogrammetrie {
    margin-top: 6rem;
    height: 600px;
  }
  .dreid {
    margin-top: 6rem;
    height: 600px;
  }
  .cards {
    width: 45rem;
    height: 40rem;
  }
  .dreiDimg {
    width: 40rem;
    height: 33rem;
  }
  .landingLinks {
    width: 42%;
  }
  .landingLinks h2 {
    font-size: 33px;
  }
  .landing-card {
    width: 500px;
  }
  .profil h1 {
    font-size: 7rem;
  }
  .kontakt h1 {
    font-size: 7rem;
  }
  .kontaktForm {
    width: 50%;
    height: max-content;
  }
  .kontakttext {
    width: 62rem;
  }
  .kontaktForm input {
    width: 25rem;
    font-size: 30px;
  }
  #nachricht {
    font-size: 30px;
    width: 62rem;
    height: 15rem;
  }
  #submitbutton {
    width: 20%;
    font-size: 30px;
  }
}

@media screen and (max-width: 1445px) {
  .landingLinks h2 {
    font-size: 22px;
  }
}

@media screen and (max-width: 1300px) {
  .landingLinks {
    width: 90%;
  }
  .landing-card {
    width: 220px;
  }
}

@media screen and (max-width: 930px) {
  .landingLinks {
    width: 90%;
    flex-wrap: wrap;
  }
  .landing-card {
    width: 280px;
  }

  .marginTop2 {
    margin-top: 0.5rem;
  }

  .homeDescription {
    font-size: 1.2rem;
    margin-bottom: 2rem;
    background-color: rgba(100, 100, 100, 0.5);
    border-radius: 10px;
  }

  .bauforschung {
    background-position-y: -20px;
    background-size: 130%;
  }

  .carouselcaption h4 {
    font-size: 0.9rem;
  }

  .carouselcaption p {
    font-size: 0.8rem;
  }

  .profileCards {
    width: 50%;
    margin: 0;
  }
  .paddingleftright1 {
    padding: 0rem 1rem;
  }

  .profil h1 {
    font-size: 3rem;
  }

  .footer {
    height: max-content;
    margin-top: 2rem;
  }
  .footericons {
    align-items: center;
    flex-direction: column;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 850px) {
  .landing-card {
    width: 220px;
  }
}

@media (max-width: 576px) {
  .logo h4 {
    font-size: 14px;
  }
  .logo {
    flex-direction: row;
  }
  .logo img {
    width: 25px;
    height: 30px;
  }
  .landingLinks {
    width: 90%;
    word-wrap: break-word;
  }

  .landing-card {
    width: 160px;
    margin: 4px;
    padding: 5px;
  }

  .landing-card h2 {
    word-wrap: break-word;
    text-align: center;
    font-size: 14px;
  }

  #profil {
    padding: 0;
    margin-top: 2rem;
    text-align: center;
  }

  .profilCol {
    flex-direction: column;
  }

  .profil h1 {
    font-size: 2.5rem;
  }
  .profileCards {
    width: 80%;
  }
  .profileImage {
    width: 45%;
  }
  .textWidth {
    width: 100%;
  }

  .collaborators {
    font-size: 0.8rem;
  }

  #kontakt {
    padding: 0;
    margin-top: 15rem;
    margin-bottom: 3rem;
  }
  .kontaktForm {
    width: 90%;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
  }

  .kontakttext {
    width: 80%;
  }

  /* .kontaktForm .kontaktbutton {
    width: 1rem;
  } */

  #nachricht {
    width: 14rem;
    height: 15rem;
  }

  .cards {
    width: 17rem;
    height: 21rem;
  }
}

@media (max-width: 320px) {
  .landing-card {
    width: 120px;
    margin: 4px;
    padding: 5px;
  }
}
